
.otherGroupDealWrapper {
    border-radius: 4px;
}
.otherGroupDeal{
    padding:16px 8px;
    cursor:pointer;
    transition: 0.3s background;
}

.createNewButton {
    padding:16px 8px;
    cursor:pointer;
    transition: 0.3s background;
}
.groupDealButtonWrapper {
    margin-top:10px;
    padding:12px 0;
}
.saveButton {
    width:250px;
}

.backButton {
    width:120px;
}
.dateRangePicker {
    padding-top:10px;
    padding-bottom:10px;
}

.statusTag {
    font-size:12px;    
    padding: 4px 10px;
    border-bottom-left-radius: 8px;    
}
.pendingTag {
    background: #FCF0D1;
    border: 1px solid #FFE39A;
    color: #E3A400;
}

.completeTag {
    background: #224E4Eba;
    border: 1px solid #224E4E;
    color: #fff;    
}