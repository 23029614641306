
.avatarWrapper {
    min-width:0;
    max-width:100%;
    padding: 0 0 10px 0; 
}
.avatarWrapperNoBottom {
    min-width:0;
    max-width:100%;
    padding: 0 0 0 0;
}

.avatarPlaceholder {
    height:32px;
}
.avatarGroup {
    margin-left:15px;
}
.avatarGroup > div {
    margin-left:-15px;
}
.extraCustomers{
    padding-left:2px;
}