
.loadingContent > *:first-child {        
    opacity:0.2;
}
.loading {    
    position:fixed;
}
.loadingAbsolute {
    position:absolute;
}

.loadingWrapper {    
    height: 100vh;    
    width: 100vw;    
    position: fixed;    
    top: 0;        
    transition:all 0.3s;
}


.pressIn {
    animation: press 0.30s cubic-bezier(.36,.07,.19,.97) both;
}
.pressOut {
    
}

@keyframes press {
    0% {        
        transform: scale3d(1,1,1);
        background:none;
    }
    50% {
        transform: scale3d(0.98,0.98,1);        
    }
    100% {
        transform: scale3d(1,1,1);        
    }
    
  }



.shake {
    
    animation: shake 0.90s cubic-bezier(.36,.07,.19,.97) both;
}
.noshake {
    
}

.shakeEnter {
    transform: translate3d(0, 0, 0);
    /* backface-visibility: hidden; */
    perspective: 1000px;    
}

.shakeEnterActive {
    animation: shake 0.80s cubic-bezier(.36,.07,.19,.97) both;

}
.shakeEnterDone {
    background:red
}

.shakeExit {
    transform: translate3d(0, 0, 0);
    /* backface-visibility: hidden; */
    perspective: 1000px;    
    
}
.shakeExitActive {
    transform: translate3d(0, 0, 0);
}
.shakeExitDone {
    transform: translate3d(0, 0, 0);
}

@keyframes shake {
    0% {
        background:#DE3051;
    }
    100% {
        
    }
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
      
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }