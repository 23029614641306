div.pageContainer {
    margin-left:8px;
    margin-right:8px;
}

div.breadcrumb {    
    padding-top:20px;
}
div.mobileBreadcrumb {
    padding-top:20px;    
}
.breadcrumbIcon {
    margin-right:4px;
}
li a.lastLink{
    color:#9e9e9e;
}
div.header {    
    padding-top:30px;
    padding-bottom:30px;    
}
div.mobileHeader {    
    padding-top:30px;
    padding-bottom:30px;    
}
.footer {
    margin-top:300px;
}

/*Page Box*/
.pageBoxContentWrapper {
    min-height:140px;
}
.pageBoxTitle {
    margin-bottom:10px;
}
.pageBoxDescription {

}
.pageBoxNavigationButton {
    min-width:250px;
}