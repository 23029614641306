div.customerDiscountToggles {
    display:flex;        
    padding:0px;        
    margin-bottom:20px;
}
.customerDiscountToggles > div {
    padding:4px;
    height:26px;
    width:150px;    
    transition:0.3s background;
}
.customerDiscountToggles > div:first-of-type {
    border-radius: 4px 0px 0px 4px;
}
.customerDiscountToggles > div:nth-of-type(2) {
    border-radius: 0px 4px 4px 0px;
}

.customerDiscountToggles > div.toggled {
    color:#fff;    
}
.discountTableWrapper {
    margin-bottom:20px;
}

.customerCards {
    margin-top:20px;
}

.customerCard {
    margin-bottom:20px;
    border-radius:8px;
    box-shadow: 0px 0px 14px rgba(0,0,0,0.17);    
}
.discountScheduleSelect {
    margin-top:10px;
}
.addCustomerButton {
    margin:10px 0;
}

.neighborhoodSelect {
    margin-top:10px;
}
.createGroupDealServiceSelect {
    margin-top:10px;
}
.nsoSelect {
    margin-top:10px;
}
.zipCodeField {
    margin-top:10px;
}
.nsoPrice {
    margin-top:10px;   
}
.maxCustomerCountFieldWrapper {
    margin-top:10px;
}
.requestNeighborhoodWrapper {
    padding:10px 0;
    cursor:pointer;
}
.greenText {
    margin-left:4px;
    font-size:14px;    
}