.wrapper {
    min-width:320px;    
}
.wrapperMobile {
    width:100%;
}
.title {
    
}
.closeProfile {
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:16px 16px 0 16px;
}
.closeProfileButton {
    display:flex;
    align-items: center;
    justify-content: right;
}
.profileDivider {
    border: 1px solid #f5f5f5;
    margin:0px 16px;
}
.profileListItem {
    padding-top:8px;
    padding-bottom:8px;
}