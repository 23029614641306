.requestSupportCard {
    box-shadow:0px 0px 15px #DCDCDC;    
    margin-top:10px;
    margin-bottom:10px;
}
.requestSupportMessage {
    margin-top:20px;
}
div.requestSupportCardContent {
    padding:20px;    
}
div.requestSupportCardContent:last-child {
    padding-bottom:20px;
}
.requestSupportCardAlert {
    padding-bottom:12px;
}
div.requestSupportModalBody {
    min-width:200px;
    margin-bottom:8px;    
}
button.escalateButton {      
    box-shadow:0px 0px 15px #DCDCDC;    
}