.header {
    padding: 15px;
    background: #FFFFFF;
    height: 75px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background: white;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%) ;
    border-bottom: 1px solid #eee;
    z-index:99;        
}

.headerContainer {
    max-width: 1240px;
}

.dualSignInMenu {    
    padding:4px;
    display:flex;
    align-items: center;
    flex-wrap:nowrap;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    cursor: pointer;
    max-height:45px;
}

.menuIcon {    
    color: #4F9866;    
    font-size: 24px;
    margin-right:4px;
}

.headerSmallText {
    font-size: 12px;
    margin-bottom: 0px;
}
.headerLargeText {
    font-size: 16px;
    margin-bottom: 0px;
}
.profileIcon{    
    background-color: #DFE9E1;
    color: #4F9866;
    border-radius: 25px;
    padding: 2px;
    font-size: 30px;
}