.subTitle {
    font-family: Sora;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin-bottom: 24px;
}

.discountScheduleTable {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}
table.discountScheduleTable {
    border-collapse: collapse;
}
.discountScheduleTable table, th, td {
    border: 1px solid #d3d3d370;
}

.discountScheduleTableHeaderRow{
    background-color:#225E4E;
    color: #FFF;
}
.neighborLabel{
    font-size: 11px;
}
.discountAmtTableHeader{
    font-size:14px;
    width: 33%;
    padding: 0.5rem; 
    border-width: 1px;
}


.discountScheduleTableRow{
    width: 33%;
    padding: 0.5rem; 
    border-width: 1px;
}


.discountTableCell {
    padding: 0.5rem;
    border-width:1px;
    height: 50px;
}

.neighborAvatarIconCell {
    width: 33%; 
    padding: 5px;
    border-width:1px;
    height:50px;
}

.sectionIcon {
    color: #CCCCC1;
    font-size: 30px;
    margin-bottom: 5px;
}

.container {
    margin-bottom: 27px;
}

.sectionDetail {
    text-align: left;
}

.currentDiscountIconColor{
    background: #8EE8B2;
}

.pendingDiscountIconColor{
    background: #FCF0D1;
}

.currentDiscountIcon {
    width: 13px;
    height: 13px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    margin-top: 14px;
}
.currentDiscountLabel {
    line-height: 1;
    margin: 12px 20px 5px 5px;
}

@media only screen and (min-width: 900px) {
    .headerContainer {
      text-align: left;
    }
  }