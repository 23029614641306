
div.breadcrumb {    
    padding:30px 0px 20px 0px;
}

div.mobileBreadcrumb {
    padding:30px 20px 20px 20px;        
}

.breadcrumbIcon {
    margin-right:4px;
}

.mobileHeader {    
    padding:0px 0px 0 0px;
}
.header {
    padding-top:10px;
    padding-bottom:10px;   
}
li a.lastLink{
    color:#9e9e9e;
}
.subtitle {
    margin:20px 0;
}

.mobileMainContent {
    padding:20px;
}
.mainContent {
    
}

.copyIcon {
    margin-right:8px;
}

.pageSubHeader {
    margin:10px 0;
}
/*CustomerSelect*/
.customerSelect {
    margin-top:10px;
    margin-bottom:10px;
}
/*MessageCopy*/
.messageCopy {
    
    border:1px solid #DCDCDC;
    
}
.messageCopy > div {
    margin-bottom:20px;
}
.messageCopy .message {
    padding:20px;
    background:#fff;
}
.messageCopy .body {
    margin-top:20px;
}
.messageCopy .bottomRow {
    margin-bottom:0;
    padding:20px;
    background: #FAFAFA;
    text-align: right;
}
.messageCopy .link {
    margin-top:20px;
    text-align: left;
}
/*BulletPoints*/
ul.bulletPoints {
    text-align: left;
    padding-left:20px;
}
.bulletPoints li {
    margin-bottom:10px;
}

/*Direct Link*/
.directLink {
    background: #FAFAFA;
    border:1px solid #DCDCDC;
    padding:20px;
}

.directLinkTitle {
    padding:20px 0;
}

.viewAllGroupDealsButton {
    width:100%;
    margin-top:30px;
    padding:10px;
    max-width:400px;
}

.footer {
    margin-top:300px;
    margin-bottom:50px;    
}
.mobileFooter {
    margin-top:300px;
    padding:0px 20px;
}

.facebookIcon {
    color:#0053B4; 
    font-size:24px;
    margin-right:20px; 
    margin-top:5px;
}

.happeningSoonDemo {
    margin:30px 0;
}
.happeningSoonHeader {
    padding-bottom:10px;
}