
/*Confirm Customer Drawer*/
.wrapper {
    position:relative;
    background: #eeeeee;
    width:500px;        
}
.wrapperMobile {
    position:relative;
    background: #eeeeee;
    width:100%;    
}
.finalConfirmationForm {

    background:#fff;
    padding:20px 0;
}
div.confirmDrawerTitle {
    padding:10px 20px;   
    font-size:20px;
}
.closeDrawer {
    height:80px;    
    background:#f5f5f5;
    width:100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;;
}
div.closeDrawerButton {    
    display:flex;
    align-items: center;
    justify-content: right;
    padding-right:20px;
}
div.serviceItemCard {    
    margin:20px;
    width:100%;
    box-shadow: 0px 0px 15px rgb(167, 166, 166);
    background: #fff;
    border-radius:12px;
}
div.setServiceDateCard {
    margin-top:20px;    
    padding:20px 20px 20px 20px;
    width:100%;
    box-shadow: 0px 0px 15px rgb(167, 166, 166);
    background: #fff;
    border-radius:12px;
}
div.serviceDatePicker {     
    margin:20px 0px 20px 0px;
}
div.serviceDatePickerOld {
    margin:20px 0px 20px 0px;
}
div.serviceItemCardTop {
    padding:20px;
}
.groupDiscount {
    background: #f1f1f1;
    margin-left:20px;
    margin-right:20px;
    margin-bottom:12px;
    padding:10px;
}
.groupDiscountHelp {    
    font-weight:700;    
}
.serviceItemPrice {    
    background:#f3f3f3;
    padding-bottom:20px;    
    padding:20px;
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.serviceItemPriceNoDiscount {
    background:#f3f3f3;
    padding-bottom:20px;    
    padding:20px;
    display:flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.discountFormula {
    display:flex;
    flex-direction: column;
    align-items:left;
}
.serviceItemPriceAmount {
    display:flex;
    flex-direction: column;
    align-items:left;
}

button.serviceItem {
    max-width:470px;        
}
div.totalPrice {
    margin-top:20px;            
}
.confirmationDivider {
    border: 1px solid #f5f5f5;
    margin-top:20px;
    width:100%;
}

div.providerWorkflowServiceDatePicker {
    margin:20px 0px 20px 0px;
}
button.cancelConfirmCustomerButton {
    margin:20px 20px;
    padding:10px 20px;
    width:100%;
}
button.saveAndCloseButton {
    margin:20px 20px 0 20px;
    padding:10px 20px;
    width:100%;
    max-width:500px;
}
.serviceItemCardHeader {
    display:flex;
    align-items: center;
    justify-content: space-between;    
}
div.statusChangeFieldWrapper {
    margin-top:50px;
    margin-bottom:30px;
    box-shadow: 0px 0px 4px #4F9866;
    padding:20px;
}
div.maxCustomerSelect {
    margin-top:20px;
    margin-bottom:20px;
}
.allowNeighborJoinSwitch {
    
}
.priceSpace {
    height:16px;
}
.progressContainer {
    height: 225px; 
    position: absolute; 
    top: 0; 
    left: 0;
    background: #fff;
    border-radius: 12px;
    border: 2px solid #fff;
}