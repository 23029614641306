.closeDrawerButton{
    padding: 0px 8px 0px 8px;
    margin-top: -10px;
}

.sectionHeaderText{
    text-align: left;
    font-weight: 600;
}

.section{
    margin-top: 20px;
}

.paymentTypeHelptext{
    font-size: 12px;
    color: #4657BB;
    font-weight: 700;
}

.testPaymentButton{
    margin-top: 20px;
}
.requestPaymentButton{
    font-weight: 600;
    margin-bottom:10px;        
}