
.generalFormWrapper{
    padding-bottom:20px;
}

.slideTitle {
    padding-bottom:12px;
}

.slideSubtitle {
    padding:10px 0;    
}

.slideDescription {
    padding: 0 0 20px 0;
}
