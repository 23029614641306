.wrapper {
    display:flex;
    border-radius: 8px;
    border:1px solid #DBDBDB;
    min-width:250px;
}
.wrapper:hover {
    cursor:pointer;
}
.count {
    font-size:34px;
    width:48px;
    padding:10px;
    border-radius: 8px 0px 0px 8px;
    font-weight:300;
}
.titleAndViewWrapper {
    padding:10px;
    text-align: left;
}
.title {

}
.view {

}