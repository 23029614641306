.mobileBreadcrumb{
    padding-top:20px;    
    margin-left:-6px;
}
.breadcrumb {
    padding-top:20px;        
    margin-left:-6px;
}
.breadcrumbIcon {
    margin-right:4px;
}
li a.mobileBreadcrumbLink {
    font-size:11px;
}
div.mobileCustomerHeader {
    font-size:18px;
}
li a.lastLink{
    color:#9e9e9e;
}

.requestPaymentButton{
    font-weight: 600;
    margin-bottom:10px;        
}

svg.mobileContactPageIcon {
    width:24px;
    color:#666;
    margin-right:8px;
    margin-left: -8px;
}
svg.contactPageIcon {
    color:#666;
    margin-right:8px;
    margin-left: -8px;
}
div.section {
    margin-bottom:8px;
}
div.desktopSection {
    margin-bottom:20px;
    padding-right:30px;
}
div.detailSection {
    margin-bottom:12px;
}
div.detailSectionLabel{

}
div.detailSectionContent{

}
.leftDivider {
    border-left:1px #666;
}
div.customerNameTitle {
    margin-top:30px;
    margin-bottom:30px;
}

.personalInformationSection {
    margin-top:10px;
}
.serviceDetailsSection {
    margin-top:10px;
}
div.initialConfirmationWrapper {    
    margin-bottom:20px;        
}
div.desktopInitialConfirmationWrapper {
    margin-bottom:20px;      
    border-left: 1px solid #e1e1e1;
    padding-left:30px;
}
.initialConfirmationFieldsWrapper {
    padding-top:10px;    
    padding-bottom:10px;
}
.providerWorkflowWrapper {
    padding-top:10px;    
    padding-bottom:10px;
}
/*Initial Confirmation*/


div.priceAndServiceDate {
    border:1px solid #dcdcdc;
    background: #f9f9f9;
}
div.priceAndServiceDateContent {
    padding:20px;    
}
div.priceAndServiceDateContent:last-child {
    padding-bottom:20px;
}
.captionDetails {
    max-width:300px;    
}

div.commonButton {    
    margin-bottom:30px;
}
button.confirmButton {        
    width:100%;
    max-width:250px;
}

.cancelButton {
    width:250px;
}
.consultationHelp {
    padding-left:30px;
}
.footer {
    margin-top:100px;
}


/*Progress Tracker */
.calendarIcon {
    margin-right:4px;
}
.editIcon {
    color:rgba(0, 0, 0, 0.54);
    margin-left:10px;
    transition:color 0.3s;
}
.editIcon:hover {
    color: #4f9866;
}

.statusContainer {
    background:#fff;
    border:1px solid #f1f1f1;
    padding:12px;
    border-radius:4px;
    margin-bottom:12px;
}
.statusLabel {
    margin-right:20px;    
    font-weight:bold;
    font-size:18px;
    line-height: initial;
}
.statusContent {
    line-height: initial;    
    color:#676767;
}

.viewAllCustomers {
    margin-top:20px;
}

@media (max-width:600px){    
    div.requestSupportModalBody {                
        min-width:auto;
    }  
}

@media (max-width:900px){
    button.confirmButton {            
        width:100%;
        max-width:100%;
    }
}

.divider {
    border: 1px solid #f5f5f5;
    margin-top:24px;
    margin-bottom:24px;
    width:100%;
}

.textLinkEmail {
    display: inline-block;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}