
/*Confirm Customer Drawer*/
.wrapper {
    position:relative;
    
    width:500px;        
}
.wrapperMobile {
    position:relative;
    
    width:100%;    
}

div.confirmDrawerTitle {
    padding:10px 20px;   
    font-size:20px;
}
.closeDrawer {
    height:80px;    
    background:#f5f5f5;
    width:100%;
    display:flex;
    align-items: center;    
    justify-content: space-between;;
}
div.closeDrawerButton {    
    display:flex;
    align-items: center;
    justify-content: right;
    padding-right:20px;
}

button.saveAndCloseButton {
    margin:20px 20px 0 20px;
    padding:10px 20px;
    width:100%;
    max-width:500px;
}


.reviewSection {
    padding:15px 0;
}

.reviewSectionTitle {
    margin-bottom:5px;
}