div.pageContainer {
    margin-left:12px;
    margin-right:12px;
}

div.breadcrumb {    
    padding-top:20px;
}
div.mobileBreadcrumb {
    padding-top:20px;    
}
.breadcrumbIcon {
    margin-right:4px;
}
li a.lastLink{
    color:#9e9e9e;
}
div.header {    
    padding-top:30px;
    padding-bottom:30px;    
}
div.mobileHeader {    
    padding-top:30px;
    padding-bottom:30px;    
}
.footer {
    margin-top:300px;
}

.mobileCreateGroupDeal {
    margin:0 0 30px 0;
    padding:10px;
    width:100%;
}

.createGroupDeal {
    margin:20px 0;
    padding:10px 40px;
}

/*Grid*/
div.nameNeighborhoodCell {
    text-align: left;    
}
div.nameNeighborhoodCell > div:first-child {
    font-weight:bold;    
}
div.nameNeighborhoodCell > div:nth-child(2){
    margin-left:8px;    
}
div.mobileNameNeighborhoodCell {
    text-align: left;    
}
div.mobileNameNeighborhoodCell > div:first-child {
    font-weight:bold;
    font-size:10px;
}
div.mobileNameNeighborhoodCell > div:nth-child(2){
    margin-left:12px;
    font-size:12px;
}
div.mobileServiceDate {
    font-size:11px;
}

div.groupDealCount {
    margin-top:12px;
    font-size:12px;
}

div.pillFilter {
    margin-right:8px;
}
div.mobilePillFilterWrapper {
    margin-top:10px;
}
div.pillFilterWrapper {

}
