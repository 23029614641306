
div.breadcrumb {    
    padding:30px 0px 20px 0px;
}

div.mobileBreadcrumb {
    padding:30px 20px 20px 20px;        
}

.breadcrumbIcon {
    margin-right:4px;
}
li a.lastLink{
    color:#9e9e9e;
}
.headerGridContainer {
    padding-bottom:20px;
}
.mobileHeader {    
    padding:10px 20px 0 20px;
}
.header {
    padding-top:10px;
    padding-bottom:20px;       
}

.mobileMainContent {
    padding:20px;
}
.mainContent {
    
}

/*Form*/
.formElement {
    width:100%;
}

.saveButton {
    width:100%;
    min-width:200px;
    max-width:400px;
}

.mobileCancelButton {
    margin-top:20px;
    width:100%;
    max-width:400px;
}

.cancelButton {
    width:100%;
    min-width:200px;
    max-width:400px;
    
}

/*Form Left Side*/
.desktopSection {
    padding:0 30px 30px 0;
}

.section {

}



.findNeighborhoodButton {
    width:100%;
    max-width:400px;
}

/*Form Right Side*/
div.customerWrapper {    
    margin-top:20px;
    margin-bottom:20px;        
}


div.desktopCustomerWrapper {
    margin-bottom:20px;      
    border-left: 1px solid #e1e1e1;
    padding-left:40px;
}


.cancelIconButton {
    margin-left:20px;
}
.footer {
    margin-top:100px;
    margin-bottom:50px;    
}
.mobileFooter {
    margin-top:100px;
    padding:0px 20px;
}