div.pageContainer {
    margin-left:8px;
    margin-right:8px;
}

div.breadcrumb {    
    padding-top:20px;
}
div.mobileBreadcrumb {
    padding-top:20px;    
}
.breadcrumbIcon {
    margin-right:4px;
}
li a.lastLink{
    color:#9e9e9e;
}
div.header {    
    padding-top:30px;
    padding-bottom:30px;    
}
div.mobileHeader {    
    padding-top:30px;
    padding-bottom:30px;    
}
.footer {
    margin-top:300px;
}

/*grid*/
div.nameCell {
    font-weight:bold;
}
div.mobileNameNeighborhoodCell {
    text-align: left;    
}
div.mobileNameNeighborhoodCell > div:first-child {
    font-weight:bold;
    font-size:10px;
}
div.mobileNameNeighborhoodCell > div:nth-child(2){
    margin-left:12px;
    font-size:12px;
}
div.mobileServiceDate {
    font-size:12px;
}
div.mobileStatusPillFilterWrapper {
    margin-top:10px;
}
div.statusPillFilterWrapper {
    
}
div.statusPillFilter {
    margin-right:8px;
}
div.numberOfCustomersWithStatus {
    margin-top:12px;
    font-size:12px;
}