
.by {
    font-style:italic;
}
.availability {
    color:#fff;
    font-weight:bold;
}
.outerPaper {    
    width: 350px;    
    border-radius:8px;    
}
@media (max-width:400px){   
    .outerPaper {
        width:300px;
    }
} 
.outerPaper:hover {
    cursor:pointer;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.20) !important;    
}
.outerPaper.spotsAvailable {
    padding:1px;
}
.innerPaper {
    padding:12px;
    border-radius:8px;
}
.outerCalendar {    
    padding-top:2px;
    width:70px;
    transition: box-shadow 0.5s;
    cursor: pointer;
}
.outerCalendar:hover {    
    cursor:default;
    box-shadow: 5px 12px 18px grey;   
    
    cursor: pointer; 
}

@keyframes wiggle {
    from {
        transform: rotateZ(0);        
    }

    40% {
        transform: rotateZ(-3deg);
    }

    75% {
        transform: rotateZ(3deg)
    }
  
    to {
        transform: rotateZ(0deg);
    }
  }

.outerCalendar > div {
    color:#fff;    
    font-weight:bold;
}
.innerCalendar {
    padding:10px 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.avatarGroup {
    margin-left:25px;
}
.avatarGroup > div {
    margin-left:-20px;
}