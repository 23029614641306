
.serviceInformationCard {
    width:100%;
    margin-bottom:20px;
    border-radius:8px;
    box-shadow: 0px 0px 14px rgba(0,0,0,0.17);    
}

.customerCard {
    border-radius:8px;
    box-shadow: 0px 0px 14px rgba(0,0,0,0.17);    
}

.addCustomerButton {
    margin:10px 0;
}