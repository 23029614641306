
.modalParent {
    display:flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.modalTitle {
    font-size:1.3em;
    font-weight:500;
    margin-bottom:20px;
}

.modalContent {
    padding-bottom:12px;
}

.modalCard {
    max-width:800px;
}