div.pageContainer {
    margin-left:20px;
    margin-right:20px;
}

div.breadcrumb {    
    padding-top:20px;
}
div.mobileBreadcrumb {
    padding-top:20px;    
}
.breadcrumbIcon {
    margin-right:4px;
}
li a.lastLink{
    color:#9e9e9e;
}
div.header {    
    padding-top:30px;
    padding-bottom:30px;    
}
div.mobileHeader {    
    padding-top:30px;
    padding-bottom:30px;    
}
.footer {
    margin-top:300px;
}

/*Static text*/
.section {
    margin:10px 0;
}
.acceptTitle {
    margin-top:20px;
}

/*Form*/
.saveButton {
    margin-top:20px;
    width:300px;
}